<template>
  <v-app app>
    <div class="free">
      <v-divider></v-divider>
      <div class="title ml-4 my-8">БЕСПЛАТНЫЕ КОНСУЛЬТАЦИИ</div>
      <v-divider></v-divider>
      <section>
        <h4 class="mt-6">Критерии выбора юридической компании</h4>
        <p>На рынке юридических услуг много многообещающих предложений, из-за большого объема информации сложно сориентироваться. Автоправо отдельная, специфическая сфера, требующая определенного объема специальных знаний и опыта. Определить компетенцию конкретного юриста сложно, поэтому первый критерий — специализация. Среди множества предложений нужно искать специалиста, который занимается именно автоправом. Сайт у компании или юриста обязательно должен быть.</p>
        <h4 class="mt-6">Как выбрать юридическую компанию автовладельцам:</h4>
        <ul>
          <li style="list-style: revert">проверить дату регистрации компании на  (достаточно ввести наименование компании в поиск); </li>
          <li style="list-style: revert">определить наличие на сайте учредительной и разрешительной документации, дипломов, грамот, портфолио; Мы предоставляем возможность скачать все наши документы на нашем сайте – мы открыты и уверены.</li>
          <li style="list-style: revert">проверить наличие договора; Сомнительные компании никогда не отправят Вам договор на предварительное уведомление</li>
          <li style="list-style: revert">сравнить цены с рыночными (стоимость услуг не может быть слишком низкая или слишком высокая).</li>
        </ul>
        <p>
          Хорошие автоюристы теперь чаще всего располагаются не в офисах, а предлагают услугу «под ключ» (от составления жалобы до получения судебного решения) дистанционно – этого требует время.
        </p>
        <h4 class="mt-6">В каких ситуациях может потребоваться помощь автоюриста</h4>
        <br>
        <p><span style="color: blue">Бесплатная юридическая консультация для водителей</span> требуется при:</p>
        <ul>
          <li>необходимости растолковать законодательные акты, относящиеся к конкретной ситуации;</li>
          <li>незаконном наложение штрафа;</li>
          <li>нарушениях при составлении документов сотрудниками ГИБДД;</li>
          <li>при лишении прав;</li>
          <li>необходимости возместить ущерб от ДТП с виновника;</li>
          <li>отказе от ремонта по гарантии;</li>
          <li>некачественном ремонте;</li>
          <li>необходимости в проведении дополнительной независимой экспертизы.</li>
        </ul>
        <p>
          При первом обращении автоюрист оценивает информацию, полученную от клиента, и документы, разъясняет ситуацию с правовой точки зрения, прогнозирует результат, помогает определиться с алгоритмом действий, дает полезные рекомендации по решению проблемы.
        </p>
        <p>
          Преимущества услуг автоюриста:
        </p>
        <ul>
          <li>возможность узнать все варианты решения проблемы;</li>
          <li>проработка выигрышной стратегии поведения во время досудебного и судебного разбирательства;</li>
          <li>квалифицированная помощь в суде, в процессе возмещения ущерба автосалоном, дилером, страховой компанией, виновником ДТП.</li>
        </ul>
        <p>
          Проблемы не всегда возникают по собственной вине, разобраться с ними без квалифицированной помощи бывает сложно. При верном выборе юридической компании можно вернуть неисправное авто или потраченные деньги. Проблемы со страховыми компаниями почти всегда требуют привлечения автоюриста. При ДТП такой специалист поможет доказать невиновность, решит все проблемы, которые возникнут в связи с неприятным инцидентом в будущем.
        </p>
      </section>
    </div>
    <div style="background-color: #74AC48; padding: 40px 0" id="ya1">
      <div class="container pr-7 pr-lg-0 px-sm-8 pr-sm-11">
        <div style="font-size: 24px!important;color: white" class="title mb-5 mb-lg-8 mb-xl-12">
          Что тут думать? Надо записываться!
        </div>
        <v-form v-model="valid">
          <v-row>
            <v-col
              cols="12"
              md="3"
              class="pa-sm-0 px-md-1 pa-0 px-2"
            >
              <v-text-field
                v-model="firstName"
                :rules="nameRules"
                :counter="10"
                label="Имя"
                solo
                clearable
                required
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              md="3"
              class="pa-sm-0 px-md-1 pa-0 px-2"
            >
              <v-text-field
                v-model="phone"
                :rules="phoneRules"
                :counter="11"
                label="Телефон"
                solo
                clearable
                required
                outlined
              />
            </v-col>

            <v-col
              cols="12"
              md="3"
              class="pa-sm-0 px-md-1 pa-0 px-2"
            >
              <v-text-field
                v-model="email"
                :rules="emailRules"
                label="E-mail"
                solo
                clearable
                required
                outlined
              />
            </v-col>
            <v-col
              cols="12"
              md="3"
              class="pa-sm-0 px-md-1 pa-0 px-2"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#404040"
                    style="color: #FFED00"
                    height="56"
                    width="100%"
                    @click="valid?send():false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon v-if="statusSend">fas fa-circle-notch fa-spin</v-icon>
                    {{statusSend? '' : 'Записаться бесплатно'}}
                  </v-btn>
                </template>
                <span>{{!valid ? 'Заполните данные' : 'Отправить'}}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>
      </div>
    </div>
  </v-app>
</template>

<script>
import axios from 'axios'
import querystring from 'querystring'
export default {
  name: 'Free',
  data () {
    return {
      valid: false,
      statusSend: false,
      firstName: '',
      phone: '',
      nameRules: [
        v => !!v || 'Введите имя',
        v => (v && v.length <= 10) || 'Имя не должно быть длиннее 10 символов'
      ],
      phoneRules: [
        value => !!value || 'Обязательное поле',
        value => (Boolean(Number(value))) || 'Введите только цифры',
        value => (value && value.length >= 11) || 'Не менее 11 цифр',
        value => (value && value.length <= 11) || 'Не более 11 цифр'
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail обязательное поле',
        v => /.+@.+/.test(v) || 'E-mail введен не правильно'
      ]
    }
  },
  methods: {
    send () {
      if (this.valid) {
        this.statusSend = true
        const formData = new FormData()
        formData.append('crm', '11')
        formData.append('pipe', '22')
        formData.append('contact[name]', this.firstName)
        formData.append('contact[199]', this.phone)
        formData.append('contact[200]', this.email)
        formData.append('lead[214]', 'magistral-avtourist.ru')
        formData.append('note', 'Заявка с magistral-avtourist.ru')

        axios.post('https://technik-crm.ru:5000/lead/import-lead', {
          name: this.firstName,
          email: this.email,
          phone: this.phone,
          additional: '',
          website: 'magistral-avtourist.ru',
          generate: 'magistral-avtourist.ru',
          city: '',
          note: 'Заявка с magistral-avtourist.ru'
        }).then(() => {
        })

        const formToMail = {
          name: this.firstName,
          email: this.email,
          phone: this.phone,
          message: 'Страница заявки: ' + this.$route.path
        }
        axios.post('/send.php', querystring.stringify(formToMail)).then(res => (res.status === 200 && console.log('success send to mail')))
        axios.post('https://urist.v-avtoservice.com/api/import-lead',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        ).then(res => {
          this.dialog = true
          this.statusSend = false

          this.$router.push({ name: 'Thanks' })
        })
      } else return false
    }
  }
}
</script>

<style scoped>
  h4 {
    color: rgba(0,0,0,.7);
    margin-bottom: 15px;
  }
  li {
    list-style: revert
  }
  #ya1 {
    width: 100vw;
    transform: translateX(-50px)translateY(40px);
    @media (max-width: 450px) {
      transform: translateX(-20px)translateY(40px);
    }
  }
</style>
